<template>
    <div class="p-col-12 p-card relative page-container">
        <!--        <div class="p-d-flex"><breadcrumb :class="'p-col-12'" :home="home" :model="breadcrumb" /></div>-->
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <!--        <TabMenu v-if="showTabs" :model="tabs" />-->

        <router-view :loading="loading" @loadingChange="loadingChange" />
    </div>
</template>

<script>
export default {
    name: 'Roles',
    props: {
        // loading: {
        //     type: Boolean,
        //     require: false,
        // },
    },
    emits: ['loadingChange'],
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        loadingChange(payload = false) {
            this.loading = payload;
        },
    },
};
</script>

<style scoped>
.relative {
    position: relative;
}
.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.pi-spinner {
    font-size: 2rem;
}
</style>
